import * as React from "react";
import Button from "../components/button/Button";
import Paragraphs from "../components/typography/Paragraph";
import TextReveal from "../components/typography/TextReveal";

const NotFoundPage = () => {
    return (
        <section className="bg-dark-1 not-found">
            <TextReveal type="h1" coverBg="primary">404</TextReveal>
            <Paragraphs>
                The page you're looking for doesn't exist.
            </Paragraphs>
            <Button href="/">Go home</Button>
        </section>
    );
};

export default NotFoundPage;
